import React, { useState, useEffect } from 'react';
import styles from './TestHistory.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function TestHistory() {
    const navigate = useNavigate(); // 创建navigate函数实例
    const [history_info, setHistoryInfo] = useState({});
    const [history_Keys, setHistoryKeys] = useState([]);
    const page_length = 11;
    const [page_index, setPageIndex] = useState(0);
    const [current_history, setCurrentHistory] = useState([]);

  

  useEffect(() => {
    const data = sessionStorage.getItem("test_history");
    const parsedData = JSON.parse(data || '{}');
    setHistoryInfo(parsedData);
    setHistoryKeys(Object.keys(parsedData).sort((a, b) => b - a));
  }, [sessionStorage.getItem("test_history")]); 

    
    useEffect(() => {
      // 根据 pageIndex 计算当前页面应显示的历史记录
      const start = page_index * page_length;
      const end = start + page_length;
      const currentKeys = history_Keys.slice(start, end);
      setCurrentHistory(currentKeys.map(key => history_info[key]));
  }, [page_index, history_Keys, history_info]);

    const handleNextPage = () => {
      if ((page_index + 1) * page_length < history_Keys.length) {
          setPageIndex(page_index + 1);
        }
    };

    const handlePrevPage = () => {
        if (page_index > 0) {
            setPageIndex(page_index - 1);
        }
    };

    // console.log(typeof history_info)
    const direct_main = () => {
      navigate('/mainpage');
    }
    const direct_practice = () => {
      // window.location.href = 'https://koispeak.com';
      window.open('https://koispeak.com', '_blank');
    }
    const direct_mock = () => {
      navigate('/mocktest');
    }
    const direct_account = () => {
      navigate('/selfaccount');
    }

    function handleClick(score, url) {
      if (score !== 0) {
          window.open(url, "_blank");
      }
    }

    function renderHistoryItems(historyData) {
      const historyKeys = Object.keys(historyData); 
      // console.log("historyData", historyData)
      // console.log("historyKeys", historyKeys)
      return historyKeys.map((key, index) => {
          // 根据 index 计算 marginTop，第一个元素为 21px，其他为 0px
          const marginTop = index === 0 ? '21px' : '0px';
          let numericKey = parseInt(key, 10) + 1;
          const userUrl = `https://koispeak.com/static/user_outputs/${localStorage.getItem("account_name")}/${numericKey}/English/index.html`;
          // console.log("userUrl", userUrl)
          return (
              <div key={key} className={styles.frame_a} style={{marginTop}} onClick={() => handleClick(historyData[key].score, userUrl)}>
                  <div className={styles.date_time}>
                      <span className={styles.cambridge_ielts_test}>{historyData[key].date}</span>
                  </div>
                  <div className={styles.frame_b}>
                      <span className={styles.travel_school_memory_childhood}>{historyData[key].name}</span>
                  </div>
                  <div className={styles.frame_c}>
                      <span className={styles.score_d}>{historyData[key].theme}</span>
                  </div>
                  <div className={styles.frame_e}>
                      <span className={styles.text_d}>{historyData[key].score}</span>
                  </div>
              </div>
          );
      });
  }
  

    return (
        <div className={styles.main_container}>
      <div className={styles.macbook_air_home_page}>
        <div className={styles.frame_1}>
          <div className={styles.about_us}><span className={styles.about_us_2}>关于我们</span></div>
          <div className={styles.about_us_3}><span className={styles.about_us_terms}>条款</span></div>
          <div className={styles.about_us_privacy}>
            <span className={styles.about_us_help}>隐私</span>
          </div>
          <div className={styles.about_us_4}><span className={styles.logo_tentative}>帮助</span></div>
        </div>
        <div className={styles.flex_row_de}></div>
        <div className={styles.frame_5}>
          <div className={styles.frame_6}>
            <div className={styles.my_mock_exam_records}>
              <span className={styles.info}>我的模考记录</span>
              <div className={styles.frame_7}></div>
            </div>
            <div className={styles.frame_8}>
              <div className={styles.time}>
                <span className={styles.test_paper}>时间</span
                ><span className={styles.section_topic}>套题</span
                ><span className={styles.score}>每部分主题</span
                ><span className={styles.frame_9}>得分</span>
              </div>
              {/* <div className={styles.frame_a}>
                <div className={styles.date_time}>
                  <span className={styles.cambridge_ielts_test}>23/10/17 14:30</span>
                </div>
                <div className={styles.frame_b}>
                  <span className={styles.travel_school_memory_childhood}>剑桥雅思16 Test 1</span>
                </div>
                <div className={styles.frame_c}>
                  <span className={styles.score_d}>Travel, school memory, childhood </span>
                </div>
                <div className={styles.frame_e}><span className={styles.text_d}>8.5</span></div>
              </div> */}
              {renderHistoryItems(current_history)}
            </div>
            <div className={styles.frame_4e}>
              <div className={styles.group} onClick={handlePrevPage} disabled={page_index === 0}></div>
              <div className={styles.frame_4f}>
                <div className={styles.ellipse}></div>
                <div className={styles.ellipse_50}></div>
                <div className={styles.ellipse_51}></div>
                <div className={styles.ellipse_52}></div>
              </div>
              <div className={styles.group_53} onClick={handleNextPage} disabled={(page_index + 1) * page_length >= history_Keys.length}></div>
            </div>
          </div>
          <div className={styles.sidebar}>
            <div className={styles.frame_54}>
              <div className={styles.sidebar_home} onClick={direct_main}>
                <div className={styles.home}><div className={styles.icon}></div></div>
                <span className={styles.my_page}>我的主页</span>
              </div>
              <div className={styles.sidebar_home_55} onClick={direct_mock}>
                <div className={styles.mock_exam}>
                  <div className={styles.trophy}><div className={styles.icon_56}></div></div>
                </div>
                <span className={styles.my_page_57}>模拟考试</span>
              </div>
              <div className={styles.sidebar_home_58} onClick={direct_practice}>
                <div className={styles.oral_practice}></div>
                <span className={styles.my_page_59}>口语练习</span>
              </div>
              <button className={styles.sidebar_home_5a}>
                <div className={styles.mock_exam_record}></div>
                <span className={styles.my_page_5b}>模考历史</span>
              </button>
            </div>
            <div className={styles.divider}><div className={styles.line}></div></div>
            <div className={styles.sidebar_home_5c} onClick={direct_account}>
              <div className={styles.my_account}></div>
              <span className={styles.my_page_5d}>我的账户</span>
            </div>
            <div className={styles.sidebar_home_5e}><div className={styles.help}></div></div>
          </div>
        </div>
      </div>
    </div>
    );
}
