import React, { useState, useEffect  } from 'react';
import styles from './Login.module.css';
import { useNavigate } from 'react-router-dom'; // 引入useNavigate钩子
import axios from 'axios';

export default function Login() {
  const navigate = useNavigate(); // 创建navigate函数实例
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [account_error, setAccountError] = useState('');
  const [password_error, setPasswordError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    // 检查 localStorage 中是否有已保存的密码
    const savedPassword = localStorage.getItem('savedPassword');
    if (savedPassword) {
        setPassword(savedPassword);
    }
  }, []);
  const handleConfirmClick = () => {
    // 使用 window.location.href 进行页面跳转
    // window.location.href = 'https://koispeak.com/static/pages/m1.html'; // 替换为你要跳转的页面的路径
    if (rememberMe) {
      // 将密码保存到 localStorage
      localStorage.setItem('savedPassword', password);
    }
      
    axios.post('http://localhost:8000/api/accounts/login/', {
      password: password,
      email: email,
    })
    .then(response => {
      const data = response.data;
      var parts = email.split('@');
      const account_name = parts[0]
      localStorage.setItem('access', data.access);
      localStorage.setItem('refresh', data.refresh);
      localStorage.setItem('account_name', account_name);
      localStorage.setItem('user_email', email);
      localStorage.setItem('password', password);
      // console.log("account_name", account_name)
      // 更新用户信息
      return axios.post('http://localhost:8000/api/user_profiles/profile/', {}, {
        headers: {
            Authorization: `Bearer ${data.access}`,
        },
      });
    })
    .then(profileResponse => {
        navigate('/mainpage');
    })
    .catch(error => {
      console.log("error", error)
      if (error.response && error.response.data) {
        const { error_code } = error.response.data;
        if (error_code == 1) {
          setAccountError('账号不存在或输入错误，请重试');
        }
        else if (error_code == 2){
          setPasswordError('密码错误，请重试');
        }
      } else {
        setAccountError('login failed. Please try again.');
      }
    });
  };
  const handleRegisterClick = () => {
    navigate('/register'); // 路由跳转到注册页面
  }
  const handleForgetClick = () => {
    navigate('/forget'); // 路由跳转到注册页面
  }
  return (
    <div className={styles.main_container}>
      <div className={styles.traced_image} />
      <div className={styles.logo_box}>
        <span className={styles.koi_speak}>KOI SPEAK</span>
      </div>
      <div className={styles.rectangle}>
        <span className={styles.email}>邮箱</span>
        <div className={styles.rectangle_1}>
          <input className={styles.email_input} 
          id="emailInput" 
          type="email" 
          placeholder="example@gmail.com" 
          required 
          value={email} 
          onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <span className={account_error ? `${styles.account_error} ${styles.visible}` : styles.account_error}>
          {account_error}
        </span>
        <span className={styles.password}>密码</span>
        <div className={styles.rectangle_2}>
          <input className={styles.password_input} 
          id="passwordInput" 
          type="password" 
          placeholder="*******" required 
          value={password} 
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="off"
          />
          
        </div>
        
        <span className={password_error ? `${styles.password_error} ${styles.visible}` : styles.password_error}>
          {password_error}
        </span>

        <div className={styles.flex_row_b}>
          <span className={styles.remember_password}>记住密码</span>
          <span className={styles.forgot_password} onClick={handleForgetClick}>忘记密码？</span>
          <input className={styles.rectangle_6} 
            type="checkbox"
            checked={rememberMe}
            onChange={handleRememberMeChange}/>
        </div>
        <button className={styles.button} onClick={handleConfirmClick}>
          <span className={styles.confirm}>确认</span>
        </button>
        {/* <span className={styles.text_9}>系统维护中，请稍后再试</span> */}
      </div>
      <span className={styles.text_a} onClick={handleRegisterClick}>注册账号</span>
    </div>
  );
}
