import React from 'react';
import styles from './Forget.module.css';
import { useNavigate } from 'react-router-dom'; // 引入useNavigate钩子

export default function Main() {
  const navigate = useNavigate(); // 创建navigate函数实例
  const ConfimRegistClick = () => {
    navigate('/login'); // 路由跳转到注册页面
  }
  return (
    <div className={styles.main_container}> 
      <div className={styles.traced_image} />
      <div className={styles.logo_box}>
        <span className={styles.koi_speak}>KOI SPEAK</span>
      </div>
      <div className={styles.rectangle}>
        <span className={styles.reset_password}>重置密码</span>
        <span className={styles.register_email}>请输入注册邮箱</span>
        
        <div className={styles.rectangle_1}>
          <input className={styles.email_example} type="password" placeholder='example@mail.com' required />
        </div>
        <button className={styles.submit_button}>
          <span className={styles.submit}>提交</span>
        </button>
      </div>
      <div className={styles.login_section}>
        <span className={styles.existing_account}>已有账号？</span>
        <span className={styles.login_button} onClick={ConfimRegistClick}>登录</span>
      </div>
    </div>
  );
}