import React, { useState, useEffect } from 'react';
import styles from './SelfAccount.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function TestHistory() {
    const [user_password, setPassword] = useState('**************');
    const [user_email, setEmail] = useState('examplemail@koispeak.com');
    const navigate = useNavigate(); // 创建navigate函数实例
    let show_password=false;
    const direct_main = () => {
      navigate('/mainpage');
    }
    const direct_practice = () => {
      window.open('https://koispeak.com', '_blank');
    }
    const direct_history = () => {
      navigate('/testhistory');
    }
    const direct_mock = () => {
      navigate('/mocktest');
    }
    const direct_example = () => {
      window.open('https://koispeak.com/static/user_outputs/example/1/English/index.html', '_blank');
    }
    useEffect(() => {
      setEmail(localStorage.getItem("user_email"));
    }, []); // 空依赖数组 [] 使得这个 effect 只在组件首次挂载时运行
    const handleShowPassword = () => {
      if (!show_password) {
        setPassword(localStorage.getItem("password")); // 显示密码
        show_password = true;
      } else {
        setPassword('**************'); // 显示密码
        show_password = false;
      }
      
    };
 
    return (
      <div className={styles.main_container}>
      <div className={styles.macbook_air_home}>
        <div className={styles.frame_1}>
          <div className={styles.about_us}><span className={styles.about_us_2}>关于我们</span></div>
          <div className={styles.about_us_3}><span className={styles.about_us_terms}>条款</span></div>
          <div className={styles.about_us_4}>
            <span className={styles.about_us_privacy}>隐私</span>
          </div>
          <div className={styles.about_us_help}><span className={styles.about_us_5}>帮助</span></div>
        </div>
        <div className={styles.logo_tentative}></div>
        <div className={styles.flex_row_d}>
          <div className={styles.regroup_i}>
            <div className={styles.frame_6}>
              <div className={styles.frame_7}>
                <div className={styles.image}>
                  <div className={styles.paper_duotone}>
                    <div className={styles.vector}></div>
                    <div className={styles.rectangle}></div>
                    <div className={styles.edit_duotone}></div>
                    <div className={styles.group}></div>
                    <div className={styles.vector_8}></div>
                    <div className={styles.vector_9}></div>
                    <span className={styles.ai}>AI</span>
                    <div className={styles.vector_a}></div>
                  </div>
                </div>
                <div className={styles.frame_b}>
                  <div className={styles.frame_c}>
                    <span className={styles.latest_ai_ielts_speaking_technology}
                      >最新AI雅思口语测评技术</span
                    >
                  </div>
                </div>
                <div className={styles.frame_d}>
                  <div className={styles.frame_e}>
                    <div className={styles.text_with_icon}>
                      <div className={styles.done_ring_round}></div>
                      <span className={styles.random_topic_bank}>快速摸底</span>
                    </div>
                    <div className={styles.text_with_icon_f}>
                      <div className={styles.done_ring_round_10}></div>
                      <span className={styles.random_topic_bank_11}>精准测评</span>
                    </div>
                  </div>
                  <div className={styles.frame_12}>
                    <div className={styles.text_with_icon_13}>
                      <div className={styles.done_ring_round_14}></div>
                      <span className={styles.random_topic_bank_15}>智能分析</span>
                    </div>
                    <div className={styles.text_with_icon_16}>
                      <div className={styles.done_ring_round_17}></div>
                      <span className={styles.random_topic_bank_18}>定制提分</span>
                    </div>
                  </div>
                </div>
                <button className={styles.frame_19}>
                  <span className={styles.my_ai_score_voucher}>我的模考评分券：</span
                  ><span className={styles.text_c}>2</span></button
                >
                <button className={styles.frame_1a} onClick={direct_example}>
                  <div className={styles.small_button}>
                    <span className={styles.view_sample_score_report}
                      >查看示例评分报告</span
                    >
                  </div>
                  {/* <div className={styles.rectangle_1b}></div> */}
                </button>
              </div>
              <div className={styles.group_1c}>
                <div className={styles.frame_1d}>
                  <div className={styles.frame_1e}>
                    <span className={styles.purchase_score}>购买评分券</span>
                  </div>
                </div>
                <div className={styles.group_1f}>
                  <div className={styles.frame_20}>
                    <div className={styles.frame_21}>
                      <span className={styles.copy_invite_code}>复制我的邀请码</span>
                    </div>
                  </div>
                  <div className={styles.info}></div>
                </div>
                <div className={styles.frame_22}>
                  <div className={styles.frame_23}>
                    <span className={styles.customer_service_appeal}>客服申诉</span>
                  </div>
                </div>
              </div>
              <div className={styles.frame_24}>
                <span className={styles.my_subscription}>我的订阅</span>
              </div>
              <div className={styles.divider}><div className={styles.line}></div></div>
            </div>
            <div className={styles.sidebar}>
              <div className={styles.frame_25}>
                <div className={styles.sidebar_my_homepage} onClick={direct_main}>
                  <div className={styles.home}><div className={styles.icon}></div></div>
                  <span className={styles.my_homepage}>我的主页</span>
                </div>
                <div className={styles.sidebar_my_homepage_26} onClick={direct_mock}>
                  <div className={styles.mock_exam}>
                    <div className={styles.trophy}><div className={styles.icon_27}></div></div>
                  </div>
                  <span className={styles.my_homepage_28}>模拟考试</span>
                </div>
                <div className={styles.sidebar_my_homepage_29} onClick={direct_practice}>
                  <div className={styles.oral_practice}></div>
                  <span className={styles.my_homepage_oral_practice}>口语练习</span>
                </div>
                <div className={styles.sidebar_my_homepage_2a} onClick={direct_history}>
                  <div className={styles.mock_exam_record}></div>
                  <span className={styles.my_homepage_mock_exam_history}>模考历史</span>
                </div>
              </div>
              <div className={styles.divider_2b}><div className={styles.line_2c}></div></div>
              <button className={styles.sidebar_my_homepage_2d}>
                <div className={styles.my_account}></div>
                <span className={styles.my_homepage_my_account}>我的账户</span>
              </button>
              <div className={styles.sidebar_my_homepage_2e}><div className={styles.help}></div></div>
            </div>
          </div>
          <div className={styles.frame_2f}>
            <div className={styles.my_account_30}>
              <div className={styles.frame_31}>
                <span className={styles.register_email}>注册邮箱</span>
              </div>
              <div className={styles.frame_32}>
                <div className={styles.frame_input} />
                <span className={styles.register_email_33}>{user_email}</span>
              </div>
            </div>
            <div className={styles.group_34}>
              <div className={styles.frame_35}>
                <div className={styles.frame_36}>
                  <span className={styles.purchase_score_37}>修改密码</span>
                </div>
              </div>
              <div className={styles.frame_38}>
                <div className={styles.frame_39}>
                  <span className={styles.purchase_score_3a}>注销账号</span>
                </div>
              </div>
            </div>
            <div className={styles.frame_3b}>
              <span className={styles.my_account_3c}>我的账号</span>
            </div>
            <div className={styles.divider_3d}><div className={styles.line_3e}></div></div>
            <div className={styles.my_account_3f}>
              <div className={styles.frame_40}>
                <span className={styles.register_email_41}>账户密码</span>
              </div>
              <div className={styles.frame_42}>
                <div className={styles.frame_input_43} /><span className={styles.register_email_44}>{user_password}</span>
              </div>
            </div>
            <div className={styles.component} onClick={handleShowPassword}>
              <div className={styles.group_45} ><div className={styles.ellipse} ></div></div>
              <div className={styles.line_46}></div>
              <div className={styles.line_47}></div>
              <div className={styles.line_48}></div>
              <div className={styles.line_49}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
}
