import React, { useState } from 'react';
import styles from './Register.module.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Register() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleLoginClick = () => {
    navigate('/login');
  };

  const ConfimRegistClick = () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    axios.post('http://localhost:8000/api/accounts/register/', {
      username: email,
      email: email,
      password: password,
      password2: confirmPassword,
      invite_code: inviteCode,
    })
    .then(response => {
      alert('Registration successful!');
      navigate('/login');
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage('Registration failed: ' + JSON.stringify(error.response.data.error));
      } else {
        setErrorMessage('Registration failed. Please try again.');
      }
    });
  };

  return (
    <div className={styles.main_container}>
      <div className={styles.image_traced} />
      <div className={styles.logo_box}>
        <span className={styles.koi_speak}>KOI SPEAK</span>
      </div>
      <div className={styles.rectangle}>
        <span className={styles.email_input}>邮箱*</span>
        <div className={styles.rectangle_1}>
          <input
            className={styles.email_example}
            type="email"
            placeholder="example@gmail.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <span className={styles.password}>密码*</span>
        <div className={styles.rectangle_2}>
          <input
            className={styles.password_input}
            type="password"
            placeholder="********"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <span className={styles.confirm_password}>确认密码*</span>
        <div className={styles.rectangle_3}>
          <input
            className={styles.password_confirm}
            type="password"
            placeholder="********"
            required
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <span className={styles.invite_code}>邀请码</span>
        <div className={styles.rectangle_5}>
          <input
            className={styles.abdmo}
            type="text"
            placeholder="ABDMO83"
            required
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
          />
        </div>
        <button className={styles.button} onClick={ConfimRegistClick}>
          <span className={styles.register}>注册</span>
        </button>
        {errorMessage && <span className={styles.error_message}>{errorMessage}</span>}
        <div className={styles.agreement_text}>
          <span className={styles.and}>已同意</span>
          <span className={styles.user_agreement}>《用户协议》</span>
          <span className={styles.and_6}>和</span>
          <span className={styles.user_agreement_7}>《隐私政策》</span>
        </div>
        <div className={styles.rectangle_8} />
      </div>
      <span className={styles.login_account} onClick={handleLoginClick}>登录账号</span>
    </div>
  );
}
